<template>
  <div :class="$style.image">
    <div id="left" :class="$style.left">
      <div :class="$style.header">
        <div :class="$style.title">
          <BjInput v-model="params.title" placeholder="请在这里输入标题" :max-length="50" />
          <span :class="$style.words">{{ params.title ? params.title.length : 0 }} / 50</span>
        </div>
        <div :class="$style.line" />
        <BjEditor ref="editor" v-model="params.content" placeholder="从这里开始写正文" />
      </div>
      <Detail ref="detail" :type="params.content_type" :data="params" />
      <div :class="$style.footer" :style="{ width: footWidth }">
        <div :class="$style.num">内容字数：{{ num }} 字</div>
        <div :class="$style.btn">
          <BjButton type="primary" @click="onSave()">
            <i class="ri-save-line left" />
            立即保存
          </BjButton>
          <BjButton @click="onSave(true)"> 保存并上架 </BjButton>
          <BjButton :class="$style.ml20" @click="onSavePreview()"> 预览 </BjButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import BjEditor from '@/components/BjEditor'
import { manageService } from '@/service'

import Detail from './Detail.vue'

const service = new manageService()

export default {
  name: 'AddImage',
  components: {
    BjEditor,
    Detail,
  },
  data() {
    return {
      params: {
        content_type: 'post',
        title: null,
        content: null,
      },
      footWidth: '800px',
      num: 0,
      material: [],
    }
  },
  watch: {
    'params.content': {
      handler() {
        if (!this.params.content) {
          return
        }
        this.num = this.params.content
          .replace(/<[^>]*>/gi, ' ')
          .replace(/<\/[^>]*>/gi, ' ')
          .replace(/&nbsp;|&#160;|&lt;|&gt;|&amp;|&quot;|&apos;|&times;|&divide;/gi, '1')
          .replace(/\s+/gi, ' ')
          .trim().length
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    if (this.$route.params.id !== 'add') {
      this.getInfo()
    }
  },
  mounted() {
    this.footWidth = document.getElementById('left').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('left').offsetWidth + 'px'
    }
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.info({
          id: this.$route.params.id,
        })
        this.params = data
        this.material = data.material
      } catch (e) {}
    },
    async onSave(type) {
      let param = _.cloneDeep(this.$refs.detail.params)
      let relation_poc = []
      this.$refs.detail.pocData.map(item => {
        relation_poc.push(item.id)
      })
      param.type_id = param.type_id ? param.type_id[param.type_id.length - 1] : null
      param.is_original_declare = param.is_original_declare ? 1 : 0
      param.group_id = Array.isArray(param.group_id) ? '' : param.group_id
      // const regex = new RegExp('<img', 'gi')
      // let content = this.params.content
      //   ? this.params.content.replace(regex, `<img style="max-width: 100%; height: auto"`)
      //   : this.params.content
      // let material = []
      // this.material.map(item => {
      //   if (content.indexOf(item.id) !== -1) {
      //     material.push(item)
      //   }
      // })
      if (!param.summary) {
        param.summary = this.$refs.editor.$refs.editor.editor
          .getContent({ format: 'text' })
          .replace(/\s*/g, '')
          .slice(0, 60)
      }
      try {
        await service.save({
          info: JSON.stringify({
            ...this.params,
            ...param,
            content: this.params.content,
            relation_poc: relation_poc,
            // material: material,
          }),
          id: this.$route.params.id !== 'add' ? this.$route.params.id : '',
          is_online: type ? 1 : 0, // 是否上架
        })
        this.$message.success('添加成功')
        this.$router.push({
          name: 'contentManage',
        })
      } catch (e) {}
    },
    async onSavePreview() {
      let param = _.cloneDeep(this.$refs.detail.params)
      let relation_poc = []
      this.$refs.detail.pocData.map(item => {
        relation_poc.push(item.id)
      })
      param.type_id = param.type_id ? param.type_id[param.type_id.length - 1] : null
      param.is_original_declare = param.is_original_declare ? 1 : 0
      param.group_id = Array.isArray(param.group_id) ? '' : param.group_id
      const regex = new RegExp('<img', 'gi')
      let content = this.params.content
        ? this.params.content.replace(regex, `<img style="max-width: 100%; height: auto"`)
        : this.params.content
      let material = []
      this.material.map(item => {
        if (content.indexOf(item.id) !== -1) {
          material.push(item)
        }
      })
      if (!param.summary) {
        param.summary = this.$refs.editor.$refs.editor.editor
          .getContent({ format: 'text' })
          .replace(/\s*/g, '')
          .slice(0, 60)
      }
      try {
        const res = await service.save({
          info: JSON.stringify({
            ...this.params,
            ...param,
            content: content,
            relation_poc: relation_poc,
            material: material,
          }),
          id: this.$route.params.id !== 'add' ? this.$route.params.id : '',
          is_online: 0,
        })
        if (this.$route.params.id === 'add') {
          this.$router.push({
            name: 'contentManageAddImage',
            params: {
              id: res.data.id,
            },
          })
        }
        this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
        this.$router.push({
          name: 'frontPreview',
          query: {
            type: 'content',
            id: res.data.id,
          },
        })
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.image {
  .header {
    padding: 20px;
    background: #fff;

    .title {
      position: relative;

      .words {
        position: absolute;
        top: 7px;
        right: 5px;
      }

      :global {
        .ant-input {
          padding-right: 70px;
          padding-left: 0;
          font-size: 20px;
          border: none;
        }

        .ant-input:focus {
          border: none;
          box-shadow: none;
        }

        .ant-form-item {
          margin-bottom: 10px;
        }
      }

      ::-webkit-input-placeholder {
        color: #999;
        font-size: 20px;
      }
    }

    .line {
      width: 100%;
      border-top: 1px solid #eee;
      margin-bottom: 15px;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: flex;
    width: 880px;
    height: 60px;
    padding: 0 20px;
    line-height: 60px;
    background: #fff;

    .num {
      width: 200px;
    }

    .btn {
      flex: 1;
      text-align: right;
      /* stylelint-disable-next-line no-descending-specificity */
      :global {
        .ant-btn {
          width: 100px;
          height: 36px;
          margin-left: 10px;
          padding: 0;
        }
      }
    }

    .ml20 {
      margin-left: 20px !important;
    }
  }
}
</style>
